// Generated by Framer (cec372b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XEJQ8p4vU"];

const variantClassNames = {XEJQ8p4vU: "framer-v-1pibuhf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "XEJQ8p4vU", image: o_dh6sC1P = {src: new URL("assets/yt2k3Ox987RuKRAZlA3Mn0P18.png", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XEJQ8p4vU", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-SubUA", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1pibuhf", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XEJQ8p4vU"} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fill", ...toResponsiveImage_194x2gw(o_dh6sC1P)}} className={"framer-11juncl"} data-framer-name={"img"} layoutDependency={layoutDependency} layoutId={"BTgKc5b0k"} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-SubUA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SubUA * { box-sizing: border-box; }", ".framer-SubUA .framer-1txwgco { display: block; }", ".framer-SubUA .framer-1pibuhf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 184px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 184px; }", ".framer-SubUA .framer-11juncl { flex: 1 0 0px; height: 100%; overflow: visible; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-SubUA .framer-1pibuhf { gap: 0px; } .framer-SubUA .framer-1pibuhf > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-SubUA .framer-1pibuhf > :first-child { margin-left: 0px; } .framer-SubUA .framer-1pibuhf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 184
 * @framerIntrinsicWidth 184
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"o_dh6sC1P":"image"}
 */
const FramerU5frmlZQO: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerU5frmlZQO;

FramerU5frmlZQO.displayName = "Client logo";

FramerU5frmlZQO.defaultProps = {height: 184, width: 184};

addPropertyControls(FramerU5frmlZQO, {o_dh6sC1P: {__defaultAssetReference: "data:framer/asset-reference,yt2k3Ox987RuKRAZlA3Mn0P18.png?originalFilename=1665987910.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerU5frmlZQO, [])